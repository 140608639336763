@import "../../functions.scss";
.app {
    display: flex;
    flex-direction: column;
    color: var(--contrast-a);
    & > * {
        margin-bottom: 32px;
    }

    .knockout {
        font-size: 25vw;
        font-weight: 800;
        text-align: center;
        line-height: 20vw;
    }

    .social {
        display: flex;
        flex-wrap: wrap;
        a {
            cursor: pointer;
            color: inherit;
            font-weight: 600;
            font-size: 18px;
            padding: 25px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:hover {
                text-decoration: underline;
            }

            svg {
                height: 84px;
                fill: #e0e6ec;
            }
        }
    }

    .title {
        @include autoWidth();
    }

    h1 {
        font-size: 44px;
        font-weight: 800;
    }

    @media screen and (max-width: 512px) {
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 16px;
        }
    }

    .theme {
        position: absolute;
        top: 32px;
        right: 32px;
        opacity: 0.25;
        transition: opacity 100ms ease;
        svg {
            fill: var(--contrast-a);
        }
        &:hover{
            opacity: 1;
        }
        .moon, .sun {
            display: none;
        }
        cursor: pointer;
        &.dark {
            .moon {
                display: none;
            }
            .sun {
                display: block;
            }
        }
        &.light {
            .moon {
                display: block;
            }
            .sun {
                display: none;
            }
        }
    }
}

.app {
    padding: 64px;
    margin: 16px;
    border: 10px solid black;
    border-image-slice: 1;
    border-image-source: $gradient;
    min-height: calc(100vh - 180px);
    background: var(--bg);
    outline: 16px solid var(--bg);
}