$titleHeight: 48px;
$gradient1: linear-gradient(60deg, #f75033, #f37055, #ef4e7b, #ea4eef);
$gradient2: linear-gradient(45deg,
	rgb(78, 231, 185),
	rgb(78, 231, 142),
	rgb(78, 132, 231),
	rgb(208, 78, 231),	
);

$gradient: $gradient2;

@mixin autoWidth() {
    width: calc(100vw - 128px);
    max-width: 840px;
    align-self: center;
}

@mixin gradientBg() {
    background-image: $gradient;
    animation: gradient 45s ease infinite;
    background-size: 400% 400%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.app {
	&.dark {
		--bg: #121212;
		--card: #fafafa;
		--contrast-a: #e7e7e7;
		--contrast-b: #121212;
	}

	&.light {
		--bg: #ffffff;
		--card: #121212;
		--contrast-a: #121212;
		--contrast-b: #e7e7e7;
	}
}

