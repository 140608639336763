@import "../../functions.scss";

.wrapper {
    display: flex;
    flex-direction: row;
    height: $titleHeight;
    line-height: $titleHeight;
    @include autoWidth();
    .title {
        cursor: pointer;
        flex-grow: 1;
        margin: 0;
        display: flex;
        flex-direction: row;
        line-height: $titleHeight;
        img {
            width: $titleHeight;
            height: $titleHeight;
            margin-right: 16px;
            fill: var(--contrast-a);
        }
        .titleText {
            color: var(--contrast-a);
            text-decoration: none;
            font-size: 28px;
            font-weight: 700;
            &.knockout {
                color: transparent;
                @include gradientBg();
                background-clip: text;
            }
        }
    }

    .hamburger {
        display: none;
        align-self: center;
        color: var(--contrast-a);
        cursor: pointer;
        svg {
            fill: var(--contrast-a);
        }
        &:hover {
            svg {
                fill: #ef4623;
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        a {
            color: var(--contrast-a);
            text-decoration: none;
            margin-left: 15px;
            cursor: pointer;
            &:not(.button):hover {
                color: #ef4623;
            }
            svg {
                margin-bottom: -1px;
            }
        }
        .button {
            align-self: center;
            background: #e7e7e7;
            color: black;
            padding: 4px 15px;
            margin-right: 15px;
            border-radius: 42px;
            &:hover {
                background: $gradient;
                background-size: 400% 400%;
                background-position: right;
                color: #EDF7F5;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .hamburger {
            display: flex;
        }
        .titleText, .menu {
            display: none;
        }
    }
}