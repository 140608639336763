@import "../../functions.scss";
$borderRadius: 20px;
.wrapper {
    @include autoWidth();
    background: var(--card);
    color: transparent;
    svg {
        fill: var(--contrast-b);
    }
    border-radius: $borderRadius;
    &.light {
        background: var(--contrast-b);
        svg {
            color: #121212;
        }
    }
    .bg {
        padding: 32px;
        background-clip: text;
        background-position: center;
        background-size: cover;
        &.autoBg {
            @include gradientBg();
        }
    }

    &:not(.knockout), &:hover {
        .bg {
            background-clip: unset;
            color: white;
            border-radius: $borderRadius;
        }
        .title, .content {
            color: white;
        }
        svg {
            fill: white;
        }
    }
    .title {
        font-weight: 600;
        color: rgba(0,0,0,0.00000001);
        font-size: 32px;
        
    }
    .content {
    }
}